<template>
  <div>
    <user-list-add-new
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      :gen-options="genOptions"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-2"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row class="px-1">
          <!-- Search -->
          <b-col
            cols="3"
            class="p-0"
          >
            <b-form-select v-model="genFilter">
              <template #first>
                <b-form-select-option :value="null"
                  >전체 기수</b-form-select-option
                >
              </template>
              <option
                v-for="(option, idx) in genOptions"
                :key="idx"
                :value="option.id"
                :title="option.genName || null"
              >
                {{ option.genName }} 기
              </option>
            </b-form-select>
          </b-col>
          <b-col
            cols="9"
            class="d-flex align-items-center justify-content-end"
          >
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              placeholder="이름을 입력하세요."
            />
            <b-button
              variant="primary"
              @click="submitForm"
            >
              <span class="text-nowrap">검색</span>
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <!-- User Items -->

    <user-item
      v-for="user in userList"
      :key="user.id"
      :data="user"
    />
    <div class="row">
      <b-col
        cols="12"
        v-if="userData.role === 'admin' || userData.role === 'manager'"
      >
        <div class="float-right">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            @click="isAddNewUserSidebarActive = true"
          >
            회원등록
          </b-button>
        </div>
      </b-col>
    </div>
    <b-col cols="12">
      <!-- pagination -->
      <div class="my-2">
        <b-pagination
          v-model="page"
          align="center"
          :total-rows="rows"
          :per-page="limit"
          use-router
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          @page-click="pageClick"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </b-col>
  </div>
</template>

<script>
  import {
    BForm,
    BCard,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BCardImg,
    BCardText,
    BFormSelect,
    BFormSelectOption,
  } from 'bootstrap-vue';
  import vSelect from 'vue-select';
  import { kFormatter } from '@core/utils/filter';
  import UserItem from './UserItem.vue';

  import UserListAddNew from './UserListAddNew.vue';

  import InfiniteLoading from 'vue-infinite-loading';
  import Ripple from 'vue-ripple-directive';

  export default {
    components: {
      // UsersListFilters,
      UserListAddNew,
      BCard,
      BCardBody,
      BCardImg,
      BCardText,
      BRow,
      BCol,
      BFormInput,
      BButton,
      BTable,
      BMedia,
      BAvatar,
      BLink,
      BBadge,
      BDropdown,
      BDropdownItem,
      BPagination,
      vSelect,
      InfiniteLoading,
      BFormSelectOption,
      BFormSelect,
      UserItem,
      BForm,
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        isAddNewUserSidebarActive: false,
        userData: JSON.parse(localStorage.getItem('userData')),
        searchQuery: '',
        userList: [],
        genOptions: [],
        genFilter: '',
        page: 1,
        limit: 10,
        rows: 0,
      };
    },
    created() {
      // this.getContent(1);
      this.$http.get('/gentype/3').then(res => {
        this.genOptions = res.data;
      });

      document.querySelector('.content-header-title').textContent = '동문검색';
    },
    methods: {
      kFormatter,
      showProfile(genName, userName, profileImage) {
        if (!profileImage) return;
        console.log(genName, userName, profileImage);

        this.$swal({
          title: `${genName}기 ${userName}`,
          html: `<img src="/uploads/profileimage/3/${profileImage}" class="img-fluid" />`,
          confirmButtonText: '닫기',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        });
      },
      async pageClick(button, page) {
        this.page = page;
        const query = Object.assign({}, this.$route.query);
        query.page = page;
        await this.$router.push({ query });
        // this.getContent(page);
      },
      async getContent(page) {
        // const id = this.$route.params.id;
        const gen = this.genFilter || '';
        const query = this.searchQuery || '';

        // const { page, gen, query...otherParams } = this.$route.query;

        this.$http
          .get(`/user/3?page=${page}&gen=${gen}&query=${query}`)
          .then(res => {
            // console.dir(res.data);
            this.userList = res.data.list;
            this.rows = res.data.count;
          });
      },
      convertGenName(genName) {
        return typeof genName === 'number' ? `${genName}기` : genName;
      },
      submitForm() {
        const gen = this.genFilter || '';
        const query = this.searchQuery || '';

        this.$router.push({ query: { page: 1, gen, query } });

        // this.$router.push({
        //   query: { page: 1, gen, query },
        // });

        // let query = this.$route.query;
        // let gen = this.genFilter || '';
        // let search = this.searchQuery;
        // this.$router.push({ query: { ...query, gen, query: search } });
      },
    },
    watch: {
      '$route.query': {
        handler: function (search) {
          // console.log('watch $route.query', search);

          this.genFilter = search.gen;
          this.searchQuery = search.query;
          this.page = search.page || 1;
          this.getContent(this.page);
        },
        deep: true,
        immediate: true,
      },

      // $route: function () {
      //   this.getContent();
      // },
    },
  };
</script>

<style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
</style>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
