<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="val => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">회원등록</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- user id -->
          <validation-provider
            #default="validationContext"
            name="기수"
            rules="required"
          >
            <b-form-group
              label="기수"
              label-for="GenTypeId"
            >
              <b-form-input
                id="GenTypeId"
                v-model="userData.GenTypeId"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="기수"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- user id -->
          <validation-provider
            #default="validationContext"
            name="아이디"
            rules="required"
          >
            <b-form-group
              label="아이디"
              label-for="user-id"
            >
              <b-form-input
                id="user-id"
                v-model="userData.userId"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="아이디"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- password -->
          <!-- <validation-provider
            #default="validationContext"
            name="비밀번호"
            rules="required"
          >
            <b-form-group
              label="비밀번호"
              v-model="userData.userPass"
              label-for="userpass"
            >
              <b-form-input
                id="userpass"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="비밀번호"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider> -->

          <!-- Full Name -->
          <validation-provider
            #default="validationContext"
            name="성명"
            rules="required"
          >
            <b-form-group
              label="셩명"
              label-for="user-name"
            >
              <b-form-input
                id="user-name"
                v-model="userData.userName"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="셩명"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- 핸드폰 -->
          <validation-provider
            #default="validationContext"
            name="핸드폰"
            rules="required"
          >
            <b-form-group
              label="핸드폰"
              label-for="mobile"
            >
              <b-form-input
                id="mobile"
                v-model="userData.mobile"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Email -->
          <validation-provider
            #default="validationContext"
            name="Email"
          >
            <b-form-group
              label="이메일"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="userData.email"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Company -->
          <validation-provider
            #default="validationContext"
            name="comName"
          >
            <b-form-group
              label="회사명"
              label-for="comName"
            >
              <b-form-input
                id="comName"
                v-model="userData.comName"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Company address -->
          <validation-provider
            #default="validationContext"
            name="address1"
          >
            <b-form-group
              label="회사주소"
              label-for="address1"
            >
              <b-form-input
                id="address1"
                v-model="userData.address1"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Company phone -->
          <validation-provider
            #default="validationContext"
            name="comPhone"
          >
            <b-form-group
              label="회사전화"
              label-for="comPhone"
            >
              <b-form-input
                id="comPhone"
                v-model="userData.comPhone"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- home address -->
          <validation-provider
            #default="validationContext"
            name="address2"
          >
            <b-form-group
              label="자택주소"
              label-for="address2"
            >
              <b-form-input
                id="address1"
                v-model="userData.address2"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- home phone -->
          <validation-provider
            #default="validationContext"
            name="homePhone"
          >
            <b-form-group
              label="자택전화"
              label-for="homePhone"
            >
              <b-form-input
                id="homePhone"
                v-model="userData.homePhone"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              회원등록
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              취소
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
  import {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
  } from 'bootstrap-vue';
  import { ValidationProvider, ValidationObserver } from 'vee-validate';
  import { ref } from '@vue/composition-api';
  import { required, alphaNum, email } from '@validations';
  import formValidation from '@core/comp-functions/forms/form-validation';
  import Ripple from 'vue-ripple-directive';
  // import vSelect from 'vue-select';
  // import countries from '@/@fake-db/data/other/countries'
  // import store from '@/store';

  export default {
    components: {
      BSidebar,
      BForm,
      BFormGroup,
      BFormInput,
      BFormInvalidFeedback,
      BButton,
      // Form Validation
      ValidationProvider,
      ValidationObserver,
    },
    directives: {
      Ripple,
    },
    model: {
      prop: 'isAddNewUserSidebarActive',
      event: 'update:is-add-new-user-sidebar-active',
    },
    props: {
      isAddNewUserSidebarActive: {
        type: Boolean,
        required: true,
      },
    },
    data() {
      return {
        required,
        alphaNum,
        email,
      };
    },
    methods: {
      onSubmit() {
        this.$http.post(`/user/register`, this.userData).then(res => {
          emit('update:is-add-new-user-sidebar-active', false);
        });
      },
    },
    setup(props, { emit }) {
      const blankUserData = {
        branchId: 3,
        userId: '',
        userName: '',
        mobile: '',
        email: '',
        role: 'member',
        address1: '',
        address2: '',
        comName: '',
        comPhone: '',
        homePhone: '',
        GenTypeId: '',
        BizTypeId: 4,
      };

      const userData = ref(JSON.parse(JSON.stringify(blankUserData)));
      const resetuserData = () => {
        userData.value = JSON.parse(JSON.stringify(blankUserData));
      };

      const { refFormObserver, getValidationState, resetForm } =
        formValidation(resetuserData);

      return {
        userData,

        refFormObserver,
        getValidationState,
        resetForm,
      };
    },
  };
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';

  #add-new-user-sidebar {
    .vs__dropdown-menu {
      max-height: 200px !important;
    }
  }
</style>
