<template>
  <b-card class="border-secondary">
    <b-row>
      <!-- User Info: Left col -->
      <b-col
        cols="12"
        xl="6"
        class="d-flex"
      >
        <!-- User Avatar & Action Buttons -->
        <div class="p-0">
          <!-- <b-avatar
            :src="userData.avatar"
            :text="avatarText(userData.fullName)"
            :variant="`light-${resolveUserRoleVariant(userData.role)}`"
            size="124px"
            rounded
          />
           -->
          <b-link
            @click="
              showProfileImage(data.genName, data.userName, data.profileImage)
            "
          >
            <b-img-lazy
              :src="
                data.profileImage
                  ? `/uploads/profileimage/3/${data.profileImage}`
                  : require('@/assets/images/avatars/avatar.jpeg')
              "
              width="94"
              rounded
            />
          </b-link>
        </div>
        <div class="pl-1">
          <h4 class="">{{ data.userName }}</h4>
          <span class="card-text">
            <p class="user-simple-text">{{ data.genName }}기</p>
            <p class="user-simple-text">{{ data.mobile }}</p>
            <p
              class="user-simple-text"
              v-show="data.comName != ''"
            >
              {{ data.comName }}
            </p>
          </span>
        </div>
      </b-col>
      <b-col
        cols="12"
        xl="6"
      >
        <!-- User Stats -->
        <div class="d-flex justify-content-between mt-1">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            size="lg"
            class="btn-icon"
            :href="`tel:${data.mobile}`"
          >
            <!-- <feather-icon icon="PhoneCallIcon" /> -->
            전화
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            size="lg"
            class="btn-icon"
            :href="`mailto:${data.email}`"
          >
            <!-- <feather-icon icon="MailIcon" /> -->
            메일
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            size="lg"
            class="btn-icon"
            :href="`sms:${data.mobile}`"
          >
            <!-- <feather-icon icon="SendIcon" /> -->
            문자
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            v-b-toggle="`collapse-${data.id}`"
            variant="primary"
            size="lg"
            class="btn-icon"
            @click="toggleButtonIcon"
          >
            <!-- <feather-icon :icon="collapseToggleIcon" /> -->
            상세정보
          </b-button>
        </div>
      </b-col>

      <!-- Right Col: Table -->
      <b-collapse
        :id="`collapse-${data.id}`"
        class="col-12 p-0"
      >
        <b-col
          cols="12"
          class="p-0"
        >
          <table class="mt-1 mt-xl-0 w-100">
            <tr>
              <th class="pb-50 w-25">
                <feather-icon
                  icon="CheckIcon"
                  class="mr-25"
                />
                <span class="font-weight-bold">이메일</span>
              </th>
              <td class="pb-50">{{ data.email }}</td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="CheckIcon"
                  class="mr-25"
                />
                <span class="font-weight-bold">회사명</span>
              </th>
              <td class="pb-50 text-capitalize">{{ data.comName }}</td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="CheckIcon"
                  class="mr-25"
                />
                <span class="font-weight-bold">회사전화</span>
              </th>
              <td class="pb-50 text-capitalize">{{ data.comPhone }}</td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="CheckIcon"
                  class="mr-25"
                />
                <span class="font-weight-bold">회사주소</span>
              </th>
              <td class="pb-50">{{ data.address1 }}</td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="CheckIcon"
                  class="mr-25"
                />
                <span class="font-weight-bold">자택전화</span>
              </th>
              <td class="pb-50 text-capitalize">{{ data.homePhone }}</td>
            </tr>
            <tr>
              <th class="pb-50">
                <feather-icon
                  icon="CheckIcon"
                  class="mr-25"
                />
                <span class="font-weight-bold">자택주소</span>
              </th>
              <td class="pb-50">{{ data.address2 }}</td>
            </tr>
          </table>
        </b-col>
      </b-collapse>
    </b-row>
  </b-card>
</template>

<script>
  import {
    BCard,
    BButton,
    BAvatar,
    BRow,
    BCol,
    BCollapse,
    VBToggle,
    BImgLazy,
    BImg,
    BLink,
  } from 'bootstrap-vue';
  import { avatarText } from '@core/utils/filter';
  import Ripple from 'vue-ripple-directive';
  import { ref } from '@vue/composition-api';
  import swal from 'sweetalert2';

  export default {
    components: {
      BCard,
      BButton,
      BRow,
      BCol,
      BAvatar,
      BCollapse,
      BImgLazy,
      BImg,
      BLink,
    },
    props: {
      // userData: {
      //   type: Object,
      //   required: true,
      // },
      data: {
        type: Object,
        required: true,
      },
    },
    directives: {
      'b-toggle': VBToggle,
      Ripple,
    },
    setup() {
      // const { resolveUserRoleVariant } = useUsersList();
      const collapseToggleIcon = ref('ChevronDownIcon');

      const toggleButtonIcon = () => {
        collapseToggleIcon.value =
          collapseToggleIcon.value === 'ChevronDownIcon'
            ? 'ChevronUpIcon'
            : 'ChevronDownIcon';
      };

      const showProfileImage = (genName, userName, profileImage) => {
        if (!profileImage) return;
        // console.log(genName, userName, profileImage);

        swal.fire({
          title: `${genName}기 ${userName}`,
          html: `<img src="/uploads/profileimage/3/${profileImage}" class="img-fluid" />`,
          confirmButtonText: '닫기',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        });
      };

      return {
        avatarText,
        // resolveUserRoleVariant,
        toggleButtonIcon,
        collapseToggleIcon,
        showProfileImage,
      };
    },
  };
</script>

<style>
  .user-simple-text {
    margin-bottom: 0.2rem !important;
  }
</style>
